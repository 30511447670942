import React, {useEffect, useRef, useState} from 'react';
import { toast } from 'react-toastify';

import { Button, Input, InputErrorMessage, InputWrapper, Label, MaskInput } from 'styles/Form';
import UserService from 'services/UserService';
import { RenderIf } from 'components/layout';
import { ChevronLeft } from 'react-feather';
import { Checkbox } from 'components/form';
import { Bold } from 'styles/Commons';
import * as S from '../styles';
import ReCAPTCHA from "react-google-recaptcha";

enum REGULAR_REGISTER_VIEW {
	USER_VIEW = 'USER_VIEW',
	ACCESS_VIEW = 'ACCESS_VIEW',
	CODE_VIEW = 'CODE_VIEW',
}

const RegularRegister = ({ showBackButton = true, backFn, registerSuccessFn }: any) => {
	const [currentView, setCurrentView] = useState(REGULAR_REGISTER_VIEW.USER_VIEW);
	const [name, setName] = useState({ value: '', hasError: false });
	const [phoneNumber, setPhoneNumber] = useState({ value: '', hasError: false });
	const [userForm, setUserForm] = useState({ invalid: true });

	const [email, setEmail] = useState({ value: '', hasError: false });
	const [password, setPassword] = useState({ value: '', hasError: false });
	const [confirmPassword, setConfirmPassword] = useState({ value: '', hasError: false });
	const [stayConnected, setStayConnected] = useState(false);
	const [accessForm, setAccessForm] = useState({ invalid: true });
	const [isValidatingData, setIsValidatingData] = useState(false);

	const [verificationCode, setVerificationCode] = useState({ value: '', hasError: false });
	const [codeForm, setCodeForm] = useState({ invalid: true });
	const [token, setToken] = useState('');
	const [isValidatingCode, setIsValidatingCode] = useState(false);
	const [captchaToken, setCaptchaToken] = useState('');
	const captchaRef = useRef(null);

	useEffect(() => {
		const isFormInvalid = (!name.value || name.hasError || !phoneNumber.value || phoneNumber.hasError);
		setUserForm({invalid: isFormInvalid});
	}, [name, phoneNumber]);

	useEffect(() => {
		const isFormInvalid = (!email.value || email.hasError || !password.value || password.hasError || !confirmPassword.value || confirmPassword.hasError || !captchaToken );
		setAccessForm({invalid: isFormInvalid});
	}, [email, password, confirmPassword, captchaToken]);

	useEffect(() => {
		const isFormInvalid = (!verificationCode.value || verificationCode.hasError);
		setCodeForm({invalid: isFormInvalid});
	}, [verificationCode]);

	const changeName = (ev: any) => {
		ev.persist();
		const value = ev.target.value;
		const nameArray = value.split(' ').filter((name: string) => name.length > 0);

		setName(() => ({
			hasError: value.length === 0 || nameArray.length < 2,
			value: value
		}));
	}

	const changePhoneNumber = (ev: any) => {
		ev.persist();
		const cleanValue = ev.target.value.replace(/\D/g,'').substring(0, 11);

		setPhoneNumber(() => ({
			hasError: cleanValue.length === 0 || cleanValue.length < 11,
			value: cleanValue
		}));
	}

	const changeEmail = (ev: any) => {
		ev.persist();
		setEmail(() => ({
			hasError: ev.target.value.length === 0 || !ev.target.value.match(/^[\w\.\-_]{1,}@[\w\.\-]{6,}/),
			value: ev.target.value
		}));
	}

	const changePassword = (ev: any) => {
		ev.persist();
		const value = ev.target.value;
		setPassword(() => ({
			hasError: value.length < 6,
			value: value
		}));
		checkPasswords(value);
	}

	const changeConfirmPassword = (ev: any) => {
		ev.persist();
		setConfirmPassword(() => ({
			hasError: ev.target.value.length < 6 || password.value !== ev.target.value,
			value: ev.target.value
		}));
	}

	const changeVerificationCode = (ev: any) => {
		ev.persist();
		setVerificationCode(() => ({
			hasError: ev.target.value.length !== 6,
			value: ev.target.value
		}));
	}

	const checkPasswords = (currentPassword: string) => {
		setConfirmPassword(old => ({
			...old,
			hasError: old.value.length < 6 || currentPassword !== old.value,
		}));
	}

	const goToAccessData = (ev: any) => {
		ev.preventDefault();
		setCurrentView(() => REGULAR_REGISTER_VIEW.ACCESS_VIEW);
	}

	const validateData = (ev: any, isResending = false) => {
		ev.preventDefault();
		setIsValidatingData(true);

		const payload = {
			name: name.value,
			email: email.value,
			password: password.value,
			phoneNumber: phoneNumber.value,
			stayConnected: stayConnected,
		}

		UserService.register(payload, captchaToken)
			.then(({ data }) => {
				setToken(() => data);
				setCurrentView(() => REGULAR_REGISTER_VIEW.CODE_VIEW);
				if (isResending) {
					toast('Código reenviado com sucesso.', { type: toast.TYPE.SUCCESS });
				}
			})
			.catch(({ data }) => {
				resetRecaptcha();
				toast(data.message, {type: toast.TYPE.ERROR});
			})
			.finally(() => setIsValidatingData(false))
	}

	const validateCode = (ev: any) => {
		ev.preventDefault();
		setIsValidatingCode(true);
		UserService.validateRegisterCode(token, verificationCode.value)
			.then(({ data }) => {
				toast(data.message, { type: toast.TYPE.SUCCESS });
				registerSuccessFn('EMAIL');
			})
			.catch(({ data }) => toast(data.message, { type: toast.TYPE.ERROR }))
			.finally(() => setIsValidatingCode(false))
	}

	const backView = () => {
		if (currentView === REGULAR_REGISTER_VIEW.USER_VIEW) {
			backFn();
			return;
		}

		if (currentView === REGULAR_REGISTER_VIEW.ACCESS_VIEW) {
			resetRecaptcha();
			setCurrentView(() => REGULAR_REGISTER_VIEW.USER_VIEW);
			return;
		}

		if (currentView === REGULAR_REGISTER_VIEW.CODE_VIEW) {
			setCurrentView(() => REGULAR_REGISTER_VIEW.ACCESS_VIEW);
		}
	}

	const resetRecaptcha = () => {
		setCaptchaToken('');
		//@ts-ignore
		captchaRef.current.reset();
	}

	return (
		<>
			<S.Header>
				<RenderIf condition={showBackButton || currentView !== REGULAR_REGISTER_VIEW.USER_VIEW}>
					<S.ReturnButton onClick={backView}>
						<ChevronLeft size="1rem" />
					</S.ReturnButton>
				</RenderIf>
				<S.Title>Crie sua conta</S.Title>
			</S.Header>

			<RenderIf condition={currentView === REGULAR_REGISTER_VIEW.USER_VIEW}>
				<form onSubmit={goToAccessData}>
					<S.SmallText>Informe seus dados para criar sua conta</S.SmallText>
					<InputWrapper hasError={name.hasError}>
						<Label data-required>Nome e sobrenome</Label>
						<Input id="name" placeholder="Nome e sobrenome" value={name.value} onChange={changeName} />
						<InputErrorMessage>Insira seu nome e sobrenome</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={phoneNumber.hasError}>
						<Label data-required>Telefone</Label>
						<MaskInput
							id="phoneNumber"
							mask="(99) 99999-9999"
							value={phoneNumber.value}
							maskPlaceholder={null}
							placeholder="(00) 00000-0000"
							onChange={changePhoneNumber} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<Button id="validateMailRegisterData" width="100%" margin="2.25rem 0 0" disabled={userForm.invalid}>Continuar</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === REGULAR_REGISTER_VIEW.ACCESS_VIEW}>
				<form onSubmit={validateData}>
					<InputWrapper hasError={email.hasError}>
						<Label data-required>E-mail</Label>
						<Input id="email" placeholder="seunome@email.com" value={email.value} onChange={changeEmail} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={password.hasError}>
						<Label data-required>Senha</Label>
						<Input id="password" type="password" value={password.value} onChange={changePassword} />
						<InputErrorMessage>Senha deve ter no mínimo 6 caracteres</InputErrorMessage>
					</InputWrapper>

					<InputWrapper hasError={confirmPassword.hasError}>
						<Label data-required>Confirmar senha</Label>
						<Input
							id="confirmPassword"
							type="password"
							value={confirmPassword.value}
							onChange={changeConfirmPassword} />
						<InputErrorMessage>As senhas são diferentes</InputErrorMessage>
					</InputWrapper>

					<Checkbox id="stayConnected" label="Manter-me conectado" onChangeFn={(isChecked: boolean) => setStayConnected(() => isChecked)} />

					<S.TermsText>Ao me cadastrar, concordo com os <a id="gotoUserTerms" href={`${process.env.REACT_APP_FAQ_URL}/termos-de-uso`} target="_blank">Termos de Uso</a> da plataforma</S.TermsText>

					<ReCAPTCHA
						sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
						ref={captchaRef}
						onChange={($event) => setCaptchaToken($event || '')}
						onExpired={resetRecaptcha}
					/>

					<Button id="validateMailRegisterCredentials" width="100%" margin="2.25rem 0 0" disabled={accessForm.invalid} loading={isValidatingData}>Continuar</Button>
				</form>
			</RenderIf>

			<RenderIf condition={currentView === REGULAR_REGISTER_VIEW.CODE_VIEW}>
				<form onSubmit={validateCode}>
					<S.SmallText>Insira o código de verificação enviado para <Bold>{email.value}</Bold> para verificar sua conta.</S.SmallText>

					<InputWrapper hasError={verificationCode.hasError}>
						<Label data-required>Código de verificação</Label>
						<MaskInput
							id="verificationCode"
							mask="******"
							value={verificationCode.value}
							maskPlaceholder={null}
							placeholder="000000"
							onChange={changeVerificationCode} />
						<InputErrorMessage>Campo inválido</InputErrorMessage>
					</InputWrapper>

					<S.SmallText>Não recebeu o código? <S.ResendCode id="MailRegisterResendCode" onClick={($event) => validateData($event, true)}>Reenviar código</S.ResendCode></S.SmallText>

					<Button id="concludeMailRegister" width="100%" margin="2.25rem 0 0" disabled={codeForm.invalid} loading={isValidatingCode}>Criar conta</Button>
				</form>
			</RenderIf>
		</>
	);
}

export default RegularRegister;
